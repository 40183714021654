import React from "react";
import PageWrapper from "../components/PageWrapper";
import Newsletter from "../sections/landing2/Newsletter";



const CPG = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          theme: "dark",
          isFluid: true,
          darkClass: "bg-gradient-4",
          buttonText: "Get started"
        }}
        footerConfig={{
          theme: "light",
          // style: "style1", //style1, style2
          style: "style5", //style1, style
        }}
      >

        {/* <HeroMini /> */}
        {/* <div style="width:100%; height:30px; background-color:#cccccc;">Banner</div>
            <iframe src="https://pixel-install.pearcommerce.com/" style="width:100%; height:100%;"></iframe> */}
        <div css={`width:100%; height:800px; `}>
          <iframe src="https://pixel-install.pearcommerce.com/" css={`width:100%; height:100%;`} allow="clipboard-read; clipboard-write"></iframe>
        </div>
        <div className="gradient-sections-wrapper bg-default-2 bg-pattern pattern-4">
          <Newsletter />
        </div>
        {/* <CTA /> */}
      </PageWrapper>
    </>
  );
};
export default CPG;
